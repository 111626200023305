<!-- eslint-disable -->
<template>
    <div id="notfound">
        <div class="notfound">
            <img src="@/assets/images/error.svg"/>
            <p>
        <span class="nl2br">
          {{ message }}
        </span>
                <br> <br>
                <a href="/">{{ returnLabel }} </a>
            </p>
        </div>
    </div>
</template>

<script>
import '@/assets/error.css'

export default {
  name: 'Error',
  data() {
    return {
      message: '系統錯誤',
      returnLabel: '返回',
      csUrl: false
    }
  },
  created() {
    this.message = this.$store.getters.errorMessage ? this.$store.getters.errorMessage : this.message
    this.$store.commit('SET_ERROR_MESSAGE', null)
  },
  mounted() {
    document.body.classList.add('error_body')
  },
  destroyed() {
    document.body.classList.remove('error_body')
  }
}
</script>

<style scoped>
    * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
</style>
